<div class="heroImage" *ngIf="heroImageURL" [style.backgroundImage]="'url(' + heroImageURL + ')'"></div>
<div class="result">
  <h2>Results</h2>
  <section *ngIf="posts.length > 0; else loading">
    <div class="newsTile container" *ngFor="let post of posts">
      <div class="newsTileImage containerItem" [style.backgroundImage]="
          'url(' + post.fields.cardImage?.fields?.file?.url + ')'
        "></div>

      <div class="newsTileContent containerItem">
        <span class="publishDate">
          {{ post.fields.publishDate | date: "dd MMM yyyy" }}</span>
        <h3>{{ post.fields.heading }}</h3>
        <p>{{ post.fields.subheading }}</p>

        <a [routerLink]="'/pages/' + category + '/' + post.fields.slug">Read More</a>
      </div>
    </div>
    <h3 class="loadingItem" *ngIf="loadingInProgress">
      Loading...Please Wait....
    </h3>
    <mat-paginator [length]="totalPages" [pageSize]="pageSize" [showFirstLastButtons]="true"
      [pageSizeOptions]="pageSizeOptions" (page)="pageClickEvent($event)" aria-label="Select page">
    </mat-paginator>
  </section>
  <ng-template #loading>
    <h3 *ngIf="loadingInProgress" class="loadingItem">
      Loading...Please Wait....
    </h3>
    <h3 *ngIf="!loadingInProgress" class="loadingItem notFound">
      No Posts Found!
    </h3>
  </ng-template>
</div>
