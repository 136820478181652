import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  catchError,
  interval, map, mergeMap, Observable, of
} from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveResponse from 'contentful-resolve-response';
import { IEntry } from '../interfaces/contentful';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  public isPreviewModeEnabled = false;
  constructor(private httpClient: HttpClient) { }
  /**
   * Gets content via an api call to our contentful proxy. Depending on the value of the isPreviewModeEnabled member, returns published or preview content.
   *
   * @param contentType - The content type, such as "blogPost" or "iconLink".
   * @param query - Additional query fields for the content request.
   * @returns Matches for requested content.
   */
  // Contetnful Response Error Event
  errorEvent(e: any): Observable<any> {
    console.log("error fetching contentful content", e);
    return of(e);
  }
  // Contetnful Response Success Event
  successEvent(response: any, showMetaData: boolean = false): any {
    if (showMetaData) {
      return { data: resolveResponse(response) as IEntry[], total: response?.total, skip: response?.skip, limit: response?.limit };
    }
    return resolveResponse(response) as IEntry[];
  }
  getContent(contentType: string, query?: object, showMetaData: boolean = false): Observable<any> {
    // combine our query with the content Type to create one single query object
    // Converting the Object to a Query String
    const queryStringWithContentType = new URLSearchParams({ content_type: contentType, ...query }).toString();
    if (this.isPreviewModeEnabled) {
      // Generating the header for preview mode
      const headers = new HttpHeaders({ isPreview: `${this.isPreviewModeEnabled}` });
      const contentfulURL = `${environment.contentful.contentfulProxyBaseURL}/spaces/${environment.contentful.space}/environments/${environment.contentful.environment}/entries?${queryStringWithContentType}`;
      return interval(2000).pipe(mergeMap(() =>
        this.httpClient.get<any>(contentfulURL, { headers }))).pipe(map((response) => this.successEvent(response, showMetaData)), catchError(e => { return this.errorEvent(e); }));
    } else {
      // Generating the header for published mode
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + environment.contentful.api_string });
      const contentfulURL = `${environment.contentful.contentfulBaseURL}/spaces/${environment.contentful.space}/environments/${environment.contentful.environment}/entries?${queryStringWithContentType}`;
      return this.httpClient.get<any>(contentfulURL, { headers }).pipe(map((response) => this.successEvent(response, showMetaData)), catchError(e => { return this.errorEvent(e); })
      );
    }
  }
}
