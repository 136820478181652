import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulCollection } from 'contentful';
import { Subject, take, takeUntil } from 'rxjs';
import { IPrmcBlogPost } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  private componentDestroyed = new Subject<void>();
  public posts: IPrmcBlogPost[] = [];
  public category?: string;
  public heroImageURL: string = '';
  public pageNumber: number = 1;
  public loadingInProgress: boolean = false;
  public pageSizeOptions: number[] = [5, 10, 25, 75, 100];
  public pageSize: number = this.pageSizeOptions[0];
  public totalPages: number = 100;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cms: ContentfulService,
    private metaTagService: MetaTagService,
    private titleService: TitleService
  ) {
    this.watchForRouteChange();
  }

  /**
* If the requested category changes, the route will change but since the target component is the same, the page won't refresh.
* So we need to watch for changes to the route and refresh the page
*/
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.getPostsInCategory();
      });
  }

  async getPostsInCategory() {
    //get the category from the route
    const encodedCategoryName = this.route.snapshot.paramMap.get("category-name");

    if (!encodedCategoryName) {
      this.router.navigateByUrl('/page-not-found');
      return;
    }

    const caterogyName = decodeURIComponent(encodedCategoryName);
    if (caterogyName === 'prmcPost') {
      this.heroImageURL = '/assets/images/TRANSPORT_HERO.png';
      this.titleService.setTitle("All Posts");
    }
    if (caterogyName === 'prmcArticle') {
      this.heroImageURL = '/assets/images/CUSTOMERS_HERO.png';
      this.titleService.setTitle("All Articles");
    }

    this.category = caterogyName;
    this.getContentWithPagination();
    this.metaTagService.updateMetaTag("About Primary Connect - Primary connect operates a National distribution network, consisting of road, rail and ocean transport services and 3PL (Third Party Logistics) storage facilities.");
  }

  private getContentWithPagination(limit: number = this.pageSizeOptions[0], skip: number = 0) {
    this.loadingInProgress = true;
    this.cms.getContent(this.category as string, { order: '-sys.createdAt', limit, skip }, true).pipe(take(1)).subscribe({
      next: (res: any) => {
        this.posts = res.data as IPrmcBlogPost[];
        this.totalPages = res?.total || 100;
        this.loadingInProgress = false;
      },
      error: (error) => {
        this.loadingInProgress = false;
        console.error(error);
      },
    });

  }

  pageClickEvent(e: PageEvent): void {
    const skipValue = (e.pageSize * e.pageIndex);
    this.getContentWithPagination(e.pageSize, skipValue);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}
